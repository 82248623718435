<template>
  <div>
    <!-- {{extras}} {{missingData}} {{totals}} {{totalsArr}} {{missingTotals}} -->

    <!-- <f-missing-totals-dp v-model="dates[0]" /> -->
    <div v-if="extras.missingDates && extras.missingPrices">
      <f-card>
        <v-row>
          <v-col cols="12">
            <f-card-title
              >{{ $t('pages.pos.missingData.dateTotalValue') }}</f-card-title
            >
          </v-col>
        </v-row>
        <div v-for="(item, index) in missingData" :key="index">
          <f-missing-totals-dp v-model="item.data" />
        </div>
        <!-- <v-btn color="primary" elevation="0" @click="add">Nova data</v-btn> -->
      </f-card>
    </div>
    <f-card v-else-if="extras.missingPrices">
      <div v-html="$t('pages.pos.missingData.informTotalValue')"></div>
      <br />
      <br />
      <div v-for="(item, index) in totalsArr" :key="index">
        <!-- {{item}} -->
        <v-text-field
          error
          outlined
          dense
          :rules="[required]"
          :label="'Valor total - ' + labels(item)"
          v-mask="currencyMask"
          v-model="missingTotals[item]"
        />
      </div>
    </f-card>
  </div>
</template>

<script>
import validatorRules from "../../helpers/validator_rules";
import DateFormater from "../../helpers/date_formater";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import FMissingTotalsDP from "./MissingTotals_datePriceLine.vue";

var currencyMask = createNumberMask({
  prefix: "R$ ",
  allowDecimal: true,
  decimalSymbol: ",",
  decimalLimit: 9,
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ".",
  allowNegative: false,
  requireDecimal: false,
});

export default {
  components: {
    "f-missing-totals-dp": FMissingTotalsDP,
  },
  data: () => ({
    currencyMask,
    rowData: {},
  }),
  computed: {
    required() {
      return validatorRules.required(this.$t('validation.required'));
    },
    extras() {
      return this.$store.state.pos.view.extras;
    },
    missingData: {
      get() {
        return this.$store.state.pos.view.config.missingData;
      },
      set(value) {
        this.$store.commit("pos/updateMissingData", value);
      },
    },
    missingTotals() {
      return this.$store.state.pos.view.config.missingTotals;
    },
    totals() {
      return this.$store.state.pos.view.totals;
    },
    totalsArr() {
      var dates = [];
      for (var prop in this.totals) {
        dates.push(prop);
      }
      return dates;
    },
    formatedDate() {
      var [year, month] = this.date.split("-");
      console.log(this.labels(`${month}-${year}`));
      return `${month}-${year}`;
    },
  },
  created() {
    if (this.extras.missingDates && this.extras.missingPrices) {
      this.missingData = [
        {
          data: {
            date: new Date().toISOString().substr(0, 7),
            value: null,
          },
          id: 1,
        },
      ];
    }
  },
  methods: {
    // test(){
    //     console.log( this.$store.state.pos.view.config.missingTotals )
    // }
    labels(value) {
      const month = DateFormater.monthNumberToString(value.slice(0, 2));
      return `${month}/${value.slice(2)}`;
    },
    add() {
      const nextID =
        this.missingData.reduce((prev, current) =>
          prev.id > current.id ? prev : current
        ).id + 1;
      this.missingData.push({
        data: {
          date: new Date().toISOString().substr(0, 7),
          value: null,
        },
        id: nextID,
      });
      // console.log( nextID )
    },
  },
};
</script>
