<template>
  <div>
    <f-default-header :title="$t('pages.pos.title')" :subtitle="$t('pages.pos.missingData.subtitle')" />

    <f-missing-totals />

    <f-actions-btn-holder>
      <v-btn elevation="0" large @click="cancel">{{ $t('labels.btnCancel') }}</v-btn>
      <v-btn color="primary" elevation="0" large @click="save">
        {{ $t('labels.btnContinue') }}
      </v-btn>
    </f-actions-btn-holder>
  </div>
</template>

<script>
import FMissingTotals from "../../components/pos/MissingTotals.vue";

export default {
  components: {
    FMissingTotals,
  },
  methods: {
    async save() {
      const result = await this.$store.dispatch("pos/adjustMissingData");

      if (!result) {
        this.$store.dispatch("dialog/show", {
          title: this.$t('labels.error'),
          content: this.$t('alerts.posInsertValues'),
          confirm: true,
        });
        return;
      } else {
        this.$router.push({
          name: "pos-preview",
        });
      }
    },
    cancel() {
      this.$router.push({
        name: "pos-upload",
      });
    },
  },
};
</script>
